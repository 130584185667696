import { ComponentStyleConfig, ThemeOverride, extendTheme } from '@chakra-ui/react'
import { colors } from './colors'

const activeLabelStyles = {
  // transform: 'scale(0.85) translateY(-24px)',
}

const inputStyles: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        height: '64px',
        padding: '15px',
        border: '1px solid rgba(0, 0, 0, 0.38)',
        boxSizing: 'border-box',
        borderRadius: '8px',
        layerStyle: 'input-text',
        fontSize: '16px',
      },
    },
    flushed: {
      field: {
        height: '32px',
        borderBottom: '1px solid',
        paddingY: '4',
        borderColor: 'illustration.darkgray',
        textStyle: 'bodyMedium',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '32px',
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    focusBorderColor: 'accent.primary',
    errorBorderColor: 'error.primary',
  },
}

const pinInputStyles: ComponentStyleConfig = {
  variants: {
    filled: {
      bg: 'informative.white-divider',
      border: '1px solid',
      borderColor: 'surface.divider-border',
      height: '68px',
      width: '37px',
      borderRadius: '8px',
      _focus: {
        borderColor: 'accent.main',
      },
    },
  },
}

const buttonStyle: ComponentStyleConfig = {
  baseStyle: {
    _hover: {},
    _focusVisible: {},
  },
  variants: {
    solid: {
      bg: 'illustration.brandgreen',
      color: 'white',
      height: '40px',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '28px',
      _disabled: {
        bg: 'illustration.lightgray',
        color: 'text.deactive',
      },
    },
  },
}

const textStyles = {
  titleLarge: {
    fontWeight: 'semibold',
    fontSize: '26px',
    lineHeight: '44px',
    color: 'text.primary',
  },
  titleMedium: {
    fontWeight: 'semibold',
    fontSize: '22px',
    lineHeight: '40px',
    color: 'text.primary',
  },
  titleSmall: {
    fontWeight: 'semibold',
    fontSize: '20px',
    lineHeight: '40px',
    color: 'text.primary',
  },
  titleXSmall: {
    fontWeight: 'semibold',
    fontSize: '18px',
    lineHeight: '36px',
    color: 'text.primary',
  },
  bodyLarge: {
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '40px',
    color: 'text.primary',
  },
  bodyMedium: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '36px',
    color: 'text.primary',
  },
  bodySmall: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'text.primary',
  },
  bodySmallSemibold: {
    fontWeight: 'semibold',
    fontSize: '16px',
    lineHeight: '32px',
    color: 'text.primary',
  },
  bodyMediumRegular: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '36px',
    color: 'text.primary',
  },
  bodySmallRegular: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '32px',
    color: 'text.primary',
  },
  captionLarge: {
    fontWeight: 'semibold',
    fontSize: '14px',
    lineHeight: '28px',
    color: 'text.primary',
  },
  captionLargeRegular: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '28px',
    color: 'text.primary',
  },
  captionMedium: {
    fontWeight: 'semibold',
    fontSize: '12px',
    lineHeight: '24px',
    color: 'text.primary',
  },
  captionMediumRegular: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    color: 'text.primary',
  },
  captionSmall: {
    fontWeight: 'semibold',
    fontSize: '10px',
    lineHeight: '20px',
    color: 'text.primary',
  },
  captionSmallRegular: {
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '20px',
    color: 'text.primary',
  },
  cardTitle: {
    fontWeight: 'semibold',
    fontSize: '18px',
    lineHeight: '36px',
    color: 'text.primary',
  },
  cardSubtitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'text.primary',
  },
  cardCaption: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '28px',
    color: 'text.primary',
  },
  carXSmall: {
    fontWeight: 'normal',
    fontSize: '8px',
    lineHeight: '16px',
    color: 'text.primary',
  },
  navigationTitle: {
    color: 'text.primary',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    textAlign: 'center',
  },
  mycarBodyMedium: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '30px',
    color: 'text.primary',
    letterSpacing: '0.25px',
  },
  mycarTitleMedium: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '0.15000000596046448px'
  },
  mycarBodySmall: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.4px',
  },
  mycarTitleSmall: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
}

const breakpoints = {
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
}

// IMPORTANT: Add main components styles from "charkaTheme.components" or customize it in below.
// Base Theme doesn't contain main componenets' styles!
const theme: ThemeOverride = extendTheme({
  components: {
    Input: { ...inputStyles },
    Button: { ...buttonStyle },
    Form: {
      ...{
        variants: {
          floating: {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
              label: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: 'absolute',
                pointerEvents: 'none',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '28px',
                mx: 2,
                px: 2,
                mt: '-10px',
                transformOrigin: 'left top',
              },
            },
          },
          'floating-icon': {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
              label: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: 'absolute',
                pointerEvents: 'none',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '28px',
                mx: 2,
                px: 2,
                mb: 2,
                mt: '-10px',
                transformOrigin: 'left top',
              },
            },
          },
        },
      },
    },
    PinInput: { ...pinInputStyles },
    Alert: {
      ...{
        variants: {
          apiError: {
            container: {
              bg: 'white',
              m: '10px',
              boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: '6px',
              border: '0.5px solid #E6E6E6',
            },
            title: {
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
              color: 'grey.800',
            },
            description: {
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
              color: 'grey.800',
            },
            icon: {
              color: 'error.primary',
            },
          },
          actionSuccess: {
            container: {
              bg: 'success.surface',
              m: '10px',
              boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: '6px',
              border: '0.5px solid',
              borderColor: 'success.divider-border',
            },
            title: {
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
              color: 'grey.800',
            },
            description: {
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
              color: 'grey.800',
            },
            icon: {
              color: 'success.icon',
            },
          },
        },
      },
    },
  },
  textStyles,
  breakpoints,
  fonts: {
    heading: `'yekanbakh', sans-serif`,
    body: `'yekanbakh', sans-serif`,
  },
  colors,
})

export default theme
