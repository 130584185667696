import { Global } from '@emotion/react'

// url('./public/fonts/yekanbakh-black.ttf'), url('./public/fonts/yekanbakh-bold.ttf'), url('./public/fonts/yekanbakh-extrablack.ttf'), url('./public/fonts/yekanbakh-extrabold.ttf'), url('./public/fonts/yekanbakh-light.ttf'), url('./public/fonts/yekanbakh-regular.ttf'), url('./public/fonts/yekanbakh-semibold.ttf'), url('./public/fonts/yekanbakh-thin.ttf')
const Fonts = () => {
  return (
    <Global
      styles={`
      @font-face {
        font-family: 'yekanbakh';
        font-style: normal;
        font-weight: 400;
        font-display: swap
        src: url('/fonts/yekanbakh-regular.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

      }
      @font-face {
        font-family: 'yekanbakh';
        font-style: normal;
        font-display: swap
        font-weight: bold;
        src: url('/fonts/yekanbakh-bold.ttf') format('truetype');
      }
      
      @font-face {
        font-family: 'yekanbakh';
        font-style: normal;
        font-display: swap
        font-weight: semibold;
        src: url('/fonts/yekanbakh-semibold.ttf') format('truetype');
      }
     
      `}
    />
  )
}
export default Fonts
